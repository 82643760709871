import React, { useState, useEffect } from "react"
import { Grid, Box, Flex, Heading, Text, Paragraph } from "theme-ui"
import { graphql, useStaticQuery, Link } from "gatsby"

const paragraphStyle = {
  lineHeight: "1.7em",
  fontSize: '18px'
}

const eventMonthStyle = {
  fontSize: "18px",
  fontWeight: 600,
  marginTop: "1em",
}

const eventDayNameStyle = {
  fontSize: "16px",
  fontWeight: 400,
}

const eventDayNumberStyle = {
  fontSize: "32px",
  fontWeight: 700,
}

const eventDayNumberStyleSmall = {
  fontSize: "22px",
  fontWeight: 700,
}

const eventHeading = {
  fontSize: "16px",
  marginBottom: "0",
}

const eventTime = {
  fontSize: "16px",
  fontWeight: "500",
}

const eventStyle = {
  width: "100%",
  border: "1px solid #E0E0E0",
  borderRadius: "15px",
  padding: "1em",
  margin: "1em 0",
}

const eventDashStyle = {
  fontSize: '32px'
}

const eventDateContainerStyle = {
  fontFamily: "Arvo",
  color: "#494091",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRight: "1.5px solid #E0E0E0",
  height: "100%",
}

const eventDateContainerWideStyle = {
  fontFamily: "Arvo",
  color: "#494091",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  borderRight: "1.5px solid #E0E0E0",
  height: "100%",
  paddingRight: '1em'
}

const eventDateContainerStyleNoBorder = {
  fontFamily: "Arvo",
  color: "#494091",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}

const days = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"]

const months = [
  { name: "January", shortName: "Jan", data: [] },
  { name: "February", shortName: "Feb", data: [] },
  { name: "March", shortName: "Mar", data: [] },
  { name: "April", data: [] },
  { name: "May", data: [] },
  { name: "June", data: [] },
  { name: "July", data: [] },
  { name: "August", data: [] },
  { name: "September", data: [] },
  { name: "October", data: [] },
  { name: "November", data: [] },
  { name: "December", data: [] },
]

function EventItem({ event }) {
  const startDate = new Date(event.startDateAndTime)
  const endDate = new Date(event.endDateAndTime)
  const day = days[startDate.getDay()]
  const dayNum = startDate.getDate()
  const endDay = days[endDate.getDay()]
  const endDayNum = endDate.getDate()
  const heading = event.heading
  const description = event.eventDescription

  const startTime = getTime(startDate)
  const endTime = getTime(endDate)

  var time = startTime
  if (endTime) {
    time = time + " - " + endTime
  }

  function getTime(date) {
    var hour = date.getHours()
    const minutes = date.getMinutes().toString().padStart(2, "0")
    const period = hour >= 12 ? " PM" : " AM"
    if (hour == 0 && minutes == 0) return ""
    if (hour > 12) hour = hour - 12
    return hour + ":" + minutes + period
  }
  return (
    <Grid columns={["2fr 8fr", '1fr 8fr']} style={eventStyle}>
      <Box>
      { !endTime &&
        <Flex style={eventDateContainerStyle}>
          
          <Text style={eventDayNameStyle}>{day}</Text>
          <Text style={eventDayNumberStyle}>{dayNum}</Text>
          
        </Flex>
        }
        { endTime &&
        <Flex style={eventDateContainerWideStyle}>
        <Flex style={eventDateContainerStyleNoBorder}>
          <Text style={eventDayNameStyle}>{day}</Text>
          <Text style={eventDayNumberStyleSmall}>{dayNum}</Text>
        </Flex>
        <Text style={eventDashStyle}>-</Text>
        <Flex style={eventDateContainerStyleNoBorder}>
          <Text style={eventDayNameStyle}>{endDay}</Text>
          <Text style={eventDayNumberStyleSmall}>{endDayNum}</Text>
        </Flex>
        </Flex>
        }
      </Box>
      <Box sx={{ padding: "0em 0.5em" }}>
        <Flex
          sx={{
            justifyContent: "space-between",
            width: "100%",
            paddingRight: "1em",
          }}
        >
          <Heading style={eventHeading} variant="h1">
            {heading}
          </Heading>
          <Heading style={eventTime}>{time}</Heading>
        </Flex>
        <Text
          as="p"
          variant="p"
          sx={{
            fontSize: ['14px', '16px', '16px', '16px'],
            maxWidth: "100%",
            marginTop: "0.5em",
            marginBottom: "0.5em",
          }}
        >
          {description}
        </Text>
      </Box>
    </Grid>
  )
}

function Event() {
  const eventData = useStaticQuery(graphql`
    query GetEventData {
      craft {
        entries(
          enabledForSite: true
          section: "events"
          orderBy: "startDateAndTime ASC"
        ) {
          ... on CRAFT_events_default_Entry {
            id
            enabled
            heading
            startDateAndTime
            endDateAndTime
            eventDescription
          }
        }
      }
    }
  `)
  const events =  eventData.craft.entries
  const eventLength = events ? events.length : 0
  
  const [upcomingEvents, setUpcomingEvents] = useState([])
  var futureEvents = []

  function getDates(events) {
    const today = new Date()
    if (eventLength && eventLength > 0) {
      for (var i = 0; i < events.length; i++) {
        const date = new Date(events[i].startDateAndTime)
        const monthNum = date.getMonth()
        const month = months[monthNum]
        if (today < date) {
          month.data.push(events[i])
          if (!futureEvents.includes(month)) {
            futureEvents.push(month)
          }
        }
      }
    }
  }

  

  useEffect(() => {
    getDates(events)
    setUpcomingEvents(futureEvents)
  },[eventData])

  console.log(futureEvents)

  return (
    <>
      <Heading variant="h1">Upcoming Events</Heading>
      {upcomingEvents == 0 ? (
        <Box>
          <Paragraph as="p" style={paragraphStyle} sx={{margin: ['1.5em 0 0'], 
  maxWidth: ["800px", "800px", "500px", "500px"]}}>No upcoming events! Check back soon</Paragraph>
        </Box>
      ) : (
        <>
          {upcomingEvents.map((month, index) => (
            <>
              <Heading variant="medium" key={index}>{month.name}</Heading>
              <ul style={{ marginLeft: '0'}}>
                {month.data.map(event => (
                  <EventItem key={event.id} event={event} />
                ))}
              </ul>
            </>
          ))}
        </>
      )}
    </>
  )
}

export default Event
