import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import {
  Heading,
  Grid,
  Container,
  Text,
  Paragraph,
  Flex,
  Box,
  Image,
} from "theme-ui"
import { Link as GatsbyLink } from "gatsby"
import Event from "../components/display/event"
import ButtonLink from "../components/inputs/buttonLink"

// retreat pictures
import background from "../images/retreats/nc-mountains.jpg"
import cabin from "../images/retreats/processed/cabin-simple.jpg"
import kitchen from "../images/retreats/processed/kitchen.png"
import livingRoom from "../images/retreats/processed/living-room.jpg"
import patio from "../images/retreats/processed/patio.jpg"

// background elements
import poppy from "../images/retreats/red-poppy.png"
import roastingMarshmallows from "../images/retreats/roasting-marshmallows.png"
import lily from "../images/retreats/lily.png"

import "./retreats.css"

const hero = {
  width: "100vw",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}

const heroTextContainer = {
  alignItems: "center",
  justifyContent: "left",
  height: "100%",
  width: "100%",
  gridTemplateRows: "repeat(10, 1fr)",
}

const heroTextContainerChild = {
  flexDirection: "column",
  width: "400px",
  justifySelf: "start",
  gridColumnStart: 3,
}

const bigImageStyle = {
  width: "800px",
  borderRadius: "30px",
  marginLeft: "-50px",
}

const smallImageStyle = {
  width: ["300px", "400px"],
  borderRadius: "5px",
}

const paragraphStyle = {
  lineHeight: "1.7em",
}

const lilyStyle = {
  width: ["220px", "419px"],
  position: "absolute",
  right: "-10px",
  top: ["-50%", "-20%"],
}

const marshmallowStyle = {
  position: "absolute",
}

const poppyStyle = {
  width: "200px",
  position: "absolute",
  left: "-10px",
}

const Retreats = () => {

  var options = {
    option: "retreats",
  }
  return (
    <Layout variant="sidebar">
      <Container
        style={hero}
        sx={{
          height: ["800px", "1000px"],
          backgroundPositionY: ["-150px", "-250px"],
        }}
      >
        <Grid columns={10} style={heroTextContainer}>
          <Grid
            style={heroTextContainerChild}
            sx={{
              gridRowStart: 5,
              "@media screen and (min-width: 100em)": {
                marginTop: "0em",
              },
            }}
          >
            <Heading
              as="h1"
              variant="large"
              sx={{ fontSize: ["40px", "54px"] }}
            >
              Explore Your Primal Nature
            </Heading>
            <Link to={"/contact"} state={options} className="heroBtn">
              Book Now
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ position: "relative" }}>
        <Image
          src={lily}
          style={lilyStyle}
          sx={{ width: ["200px", "300px", "300px", "400px"], top: ["-280px", "-400px", "-380px","-320px"] }}
        />
        <Grid columns={[1, 1, 2]} gap={[0, 1]}>
          <Image
            src={cabin}
            style={bigImageStyle}
            sx={{ width: ["1000px", "1000px", "1500px", "800px"] }}
          />
          <Flex
            sx={{
              padding: "1em 3em",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Heading
              as="h1"
              variant="medium"
              sx={{ marginTop: ["2em", "1em", 0] }}
            >
              Firefly Lodge
            </Heading>
            <Paragraph as="p" style={paragraphStyle} sx={{margin: ['1.5em 0 0'], 
  maxWidth: ["800px", "800px", "500px", "500px"]}}>
              This is Deb Weir’s retreat facility that she purchased in 2022.
              Although it is a “work in progress,” she is now hosting small
              retreats here. At present, there is a large outdoor covered venue
              that is equipped to handle various gatherings, such as day
              workshops, small weekend retreats, business meetings, artwork
              events, concerts, movie showings, support groups, and more. There
              is also a small cabin that will sleep 3-5 guests comfortably, and
              1 small cottage for 1-2 guests. There is a campsite with access to
              an outdoor kitchen and bathroom with shower.
            </Paragraph>
          </Flex>
        </Grid>
        <Grid
          columns={['80% 80% 80%', '80% 80% 80%', 3]}
          sx={{
            margin: ["6rem 10%", "6rem 10%", "8rem auto"],
            overflowX: "auto",
            width: ["100%", "100%", "85%"],
          }}
        >
          <Image src={kitchen} style={smallImageStyle} />
          <Image src={livingRoom} style={smallImageStyle} />
          <Image src={patio} style={smallImageStyle} />
        </Grid>
      </Container>
      <Grid sx={{ background: "pink", position: "relative" }}>
        <Container variant="layout">
          <Heading
            as="h1"
            variant="medium"
            sx={{ color: "black", textAlign: "center", marginBottom: ["1em"] }}
          >
            Reads reviews from women who have attended
          </Heading>
          <Grid
            gap={[2, 2, 2, 2]}
            row={[4, 2, 1, 1]}
            columns={[1, 2, 2, 4]}
            sx={{ padding: ["0em", "3em"], marginBottom: ["1em"] }}
          >
            <Text as="p" sx={{ textSize: ["22px, 16px"], margin: "1em" }}>
              "Though I miss my daughters and my hubby too, this is where I
              needed to be. A time to heal, a time to bond with some incredible
              women in nature!" J.H.
            </Text>
            <Text as="p" sx={{ textSize: ["22px, 16px"], margin: "1em" }}>
              "We do come home, in a way, when we leave our ‘homes’ and
              reconnect with nature." J.G.
            </Text>
            <Text as="p" sx={{ textSize: ["22px, 16px"], margin: "1em" }}>
              "I came to this weekend with a lot of stress and a drained feeling
              from recent events, but just being in nature in a quiet,
              tree-filled space allowed me to let all that go and just be...what
              a content and peaceful feeling..." M.R.
            </Text>
            <Text as="p" sx={{ textSize: ["22px, 16px"], margin: "1em" }}>
              "I have figured out why I come to these retreats. It is a way of
              visiting myself. Here, I'm not the me at work or the me at home or
              the mommy. I'm just the me that's me." C.D.
            </Text>
          </Grid>
        </Container>
        <Image
          src={roastingMarshmallows}
          style={marshmallowStyle}
          sx={{
            width: ["350px", "500px"],
            bottom: ["-18%", "-30%"],
            position: ["relative", "absolute"],
            display: "flex",
            justifySelf: "center",
          }}
        />
      </Grid>
      <Container
        sx={{
          position: "relative",
          marginTop: ["10em"],
          marginBottom: ["12em", "10em"],
        }}
      >
        <Image
          src={poppy}
          style={poppyStyle}
          sx={{ width: ["200px", "400px"], bottom: ["-200px", "-100px"] }}
        />
        <Container variant="layout">
          <Event />
        </Container>
      </Container>
    </Layout>
  )
}

export default Retreats
