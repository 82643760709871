import React from "react"
import { Link } from "gatsby"
import {
    useThemeUI,
  } from "theme-ui"
import styled from '@emotion/styled'


const StyledButtonLink = styled(Link)(props => ({
  fontSize: "20px",
  fontWeight: 600,
  fontStyle: "inherit",
  textDecoration: "auto",
  display: "block",
  padding: "0.4em 0em",
  padding: "0.3em 0.7em",
  width: "fit-content",
  color: props.colorMode === 'dark' ? "white" : "#350e5a",
  border: "3px solid",
  borderColor: props.colorMode === 'dark' ? "white" : "#350e5a",
  borderRadius: "16px",
  "&:hover": {
    color: props.colorMode === 'dark' ? "#350e5a" : "white",
    background: props.colorMode === 'dark' ? "white" : "#350e5a",
  }
}))

function ButtonLink({ to, state, children }) {
  const context = useThemeUI()
  const { colorMode } = context
  console.log(colorMode)
  return (
    <StyledButtonLink colorMode={colorMode} to={to} href={to} state={state}>
      {children}
    </StyledButtonLink>
  )
}

export default ButtonLink
